import { pl } from 'date-fns/locale';
import { format } from 'date-fns';

export const dateHandler = {
  data() {
    return {
      params: {
        dateFrom: '',
        dateTo: '',
      },
    };
  },
  methods: {
    sortDates() {
      if (this.params.dates.length) {
        const dateFrom = this.params.dates[0] || '';
        const dateTo = this.params.dates[1] || '';

        if (dateFrom) {
          this.params.dateFrom = format(new Date(dateFrom), 'yyyy-MM-dd', {
            locale: pl,
          });
        }

        if (dateTo) {
          this.params.dateTo = format(new Date(dateTo), 'yyyy-MM-dd');
        }
      }
    },
  },
};
