import config from '../../configuration.json';
import axios from 'axios';
import QueryHandler from '../utils/QueryHandler';

const url = `${config.VUE_APP_API_URL}/salesmen`;

axios.defaults.withCredentials = true;

class SalesmanService {
  static async delete(id) {
    const resultUrl = `${url}/${id}`;
    const res = await axios.delete(resultUrl);
    return res.data;
  }
  static async getAllTraders(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getOne(id) {
    const res = await axios.get(`${url}/${id}`);
    return res.data;
  }

  static async getCompanies(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/companies`, params);
    const res = await axios.get(resultUrl);
    return res.data;
  }
  static async update(id, salesman) {
    const res = await axios.put(`${url}/${id}`, salesman);
    return res.data;
  }
  static async create(salesman) {
    const res = await axios.post(`${url}`, salesman);
    return res.data;
  }
  static async getAllSalesmanCompanies() {
    const res = await axios.get(`${url}/companies`);
    return res.data;
  }

  static async getAllSalesmanOrders(params = {}) {
    const resultUrl = QueryHandler.buildQuery(`${url}/orders`, params);

    const res = await axios.get(resultUrl);
    return res.data;
  }

  static async getSalesmenForFilters() {
    const res = await axios.get(`${url}/filter-version`);
    return res.data;
  }
}

export default SalesmanService;
