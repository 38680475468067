import { format } from 'date-fns';

export const formatDate = {
  methods: {
    formatDate(date) {
      if (!date) {
        return '';
      }

      return format(new Date(date), 'HH:mm, dd.MM.yyyy');
    },
  },
};
